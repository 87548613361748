import React, { useId } from 'react';

import RoundedBox from '../RoundedBox';

import style from './style.module.scss';

interface props {
  group: string;
  iban: string;
  email: string;
  defaultChecked?: boolean;
  value?: any;
  checked?: boolean;
  onChange?: (event: any) => void;
}
const MerchantRadioBox = ({ iban, group, defaultChecked, value, checked, onChange }: props): React.JSX.Element => {
  const randomId = useId();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={randomId}>
        <RoundedBox
          className={style.boxsizer}
          style={
            (checked && defaultChecked) || checked
              ? { borderColor: '#2293FB', backgroundColor: '#2293FB0D' }
              : { borderColor: '#d2d2d2', backgroundColor: undefined }
          }
        >
          <div className={style.radioboxcontainer}>
            <input
              name={group}
              type="radio"
              value={value}
              id={randomId}
              onChange={onChange}
              checked={checked}
              defaultChecked={defaultChecked}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <span className="radio" aria-valuetext={value} onClick={onChange} onKeyUp={onChange}></span>
            </label>
          </div>
          <div>
            <div className={style.iban}>{iban}</div>
            {/*<div className={style.email}>{email}</div>*/}
          </div>
        </RoundedBox>
      </label>
    </>
  );
};
export default MerchantRadioBox;
