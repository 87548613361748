import React from 'react';
import { RouterProvider } from 'react-router-dom';

import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './custom.scss';

import AppProvider from 'shared-components/providers/AppProvider';
import AuthEIDProvider from 'shared-components/providers/AuthEIDProvider';

import router from './utils/router';

declare global {
  interface Window {
    App: any;
  }

  let App: any;
}
window.App = window.App || ({} as { channel: BroadcastChannel });

App.logData = (data: object) => {
  console.log(`LOGGER: ${JSON.stringify(data)}`);
};

window.addEventListener('error', function (error) {
  console.error(error);
  App.logData(error.message);
  error.preventDefault();
});

App.channel = new BroadcastChannel('persist_data');

App.sendMessage = (message: string) => {
  return new Promise(function (resolve, reject) {
    const messageChannel = new MessageChannel();
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    messageChannel.port1.onmessage = function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };
    navigator.serviceWorker?.controller?.postMessage(message, [messageChannel.port2]);
  });
};

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);

root.render(
  <AppProvider>
    <AuthEIDProvider>
      <RouterProvider router={router} future={{ v7_startTransition: true }} fallbackElement={<div>test</div>} />
    </AuthEIDProvider>
  </AppProvider>
);
