import React from 'react';
import { Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import CentralFrame from '../../components/CentralFrame';

import style from './style.module.scss';

const FailurePayment = () => {
  const [searchParams] = useSearchParams();
  return (
    <>
      <center className="mt-5">
        <CentralFrame>
          <h1 className={style.h1}>Payment failure</h1>
          <p className={style.secondline}>Something went wrong</p>
          {searchParams.get('errorString') && <p>Error from server: {searchParams.get('errorString')}</p>}
          <Button href="/">Try one more time</Button>
        </CentralFrame>
      </center>
    </>
  );
};
export default FailurePayment;

/*
          <div className={dataLoaded ? style.hidden : style.visible}>
            Loading data...
          </div>
*/
