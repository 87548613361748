import React, { useCallback, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';

import CentralFrame from '../../components/CentralFrame';
import api from '../../utils/api';
import RegisterModal from '../../components/Modals/RegisterModal';

const Register = () => {
  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [iban, setIban] = useState('');
  const [password, setPassword] = useState('');
  const [registerModalShow, setRegisterModalShow] = useState(false);

  const handleChange = useCallback((event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case 'name': {
        setName(value);
        break;
      }
      case 'password': {
        setPassword(value);
        break;
      }
      case 'iban': {
        setIban(value);
        break;
      }
      case 'email': {
        setUser(value);

        break;
      }
    }
  }, []);
  const handleModalClose = useCallback(() => {
    setRegisterModalShow(false);
  }, []);

  const handleClick = useCallback(() => {
    const public_keys = ['public_key1', 'public_key2'];

    api.signupMerchant(user, password, name, iban, public_keys).then((response) => {
      if (!response.error) {
        setRegisterModalShow(true);
      }
      //        environment === "dev" && setDebug(response);

      //        setModalVisible(true);
    });
  }, [iban, name, password, user]);

  return (
    <>
      <RegisterModal show={registerModalShow} handleClose={handleModalClose}></RegisterModal>
      <CentralFrame>
        <center>
          <h2>Welcome</h2>
          <h1>Start your onboarding journey</h1>
        </center>
        <Form>
          <FloatingLabel controlId="onboardEmail" label="Email address" className="mb-3">
            <Form.Control name="email" type="email" placeholder="name@example.com" onChange={handleChange} />
          </FloatingLabel>
          <FloatingLabel controlId="onboardPassword" label="Passsword" className="mb-3">
            <Form.Control name="password" type="password" placeholder="" onChange={handleChange} />
          </FloatingLabel>
          <FloatingLabel controlId="onboardName" label="Name" className="mb-3">
            <Form.Control name="name" type="text" placeholder="your name" onChange={handleChange} />
          </FloatingLabel>
          <FloatingLabel controlId="onboardIban" label="IBAN" className="mb-3">
            <Form.Control name="iban" type="text" placeholder="IBAN" onChange={handleChange} />
          </FloatingLabel>
        </Form>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" onClick={handleClick}>
            Continue
          </Button>
        </div>
      </CentralFrame>
    </>
  );
};

export default Register;
