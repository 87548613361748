import { createContext } from 'react';

const userData = {
  userData: { email: '', iban: '', loggedIn: false }
};

export const UserContext = createContext(userData);

const donateData = {
  merchant: 0,
  merchantMail: '',
  amount: 0,
  currency: ''
};

export const DonateContext = createContext(donateData);
