import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { BaseProps } from '../../../utils/const';
import { Ok } from '../../../assets/icons';
import Icon from '../../Icon';

interface props extends BaseProps {
  children?: React.ReactNode;
  user?: string;
  hasCloseButton: boolean;
  isOpen: boolean;
  onClose?: () => void;
  onOkButtonClicked?: () => void;
}
const PaymentDetectedModal = ({ children, isOpen, onClose }: props): React.JSX.Element => {
  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon icon={Ok} />
            Payment Detected
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentDetectedModal;
