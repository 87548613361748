import React, { createRef, useEffect, useState } from 'react';

import { BaseProps } from '../../utils/const';
import api from '../../utils/api';
import CentralFrame from '../CentralFrame';

interface IMandatoComponent extends BaseProps {
  merchantKey: string;
  depositAmount: number;
}

const MandatoComponent = ({ merchantKey, depositAmount }: IMandatoComponent) => {
  const _timer = createRef();

  const [progress, setProgress] = useState(false);
  const [_gatewayUrl, _setGatewayUrl] = useState('');
  const [_myWindow, _setMyWindow] = useState<any>();
  useEffect(() => {
    if (progress) {
      return;
    }
    setProgress(true);
    api
      .consumeMerchantToken(merchantKey)
      .then((response) => {
        if (response === undefined) {
          return;
        }
        if (!response.error) {
          // NOTHING
        }
      })
      .then(() => {
        api.mandatoDeposit(merchantKey, depositAmount).then((response) => {
          if (response === undefined) {
            return;
          }
          if (!response.error) {
            //            setGatewayUrl(response.data.account);
            //            setMyWindow(window.open(response.data.account,'newwindow','width=1000,height=800'));
            window.location.href = response.data.account;
          }
        });
      });
  }, [merchantKey, depositAmount, progress]);
  /*
  useEffect( () => {
    const interval = setInterval(() => {
        if (myWindow?.closed) {
            clearInterval(interval);
            console.log("CLOSED WINDOW");
        }
    }, 1000);
    return() => clearInterval(interval);
  },[myWindow?.closed, myWindow?.location]);
*/
  return (
    <>
      <center>
        <CentralFrame>
          <center>Waiting for data</center>
        </CentralFrame>
      </center>
    </>
  );
};

export default MandatoComponent;
