import React, { CSSProperties } from 'react';

import { clsx } from 'clsx';

import mstyle from './style.module.scss';

interface props {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  style?: CSSProperties;
}
const RoundedBox = ({ children, label, className, style }: props): React.JSX.Element => {
  return (
    <div className={clsx(mstyle.container, className)} style={style}>
      <div className={mstyle.label_placeholder}>
        <div>{label}</div>
      </div>
      {children}
    </div>
  );
};

export default RoundedBox;
