import React, { createContext, useContext, useState } from 'react';

import useSWComm from '../hooks/useSWComm';
import { BaseProps } from 'shared-components/utils/base_interface';
import { Method, Service } from 'shared-components/configuration';

type AppContextProps = {
  values: {
    protectedSite: boolean;
    loggedIn: boolean;
    serviceWorkerReady: boolean;
    status: any;
    needAuth: boolean;
    searchBox?: string;
  };
  setters: {
    setProtected: (value: boolean) => void;
    setLoggedIn: (value: boolean) => void;
    setNeedAuth: (value: boolean) => void;
    setSearchBox: (value?: string) => void;
  };
};

const initialValues: AppContextProps = {
  values: {
    protectedSite: false,
    loggedIn: false,
    serviceWorkerReady: false,
    status: {},
    needAuth: false,
    searchBox: undefined
  },
  setters: {
    setProtected: function (_: boolean): void {
      throw new Error('Function not implemented.');
    },
    setLoggedIn: function (_: boolean): void {
      throw new Error('Function not implemented.');
    },
    setSearchBox: function (_?: string): void {
      throw new Error('Function not implemented.');
    },
    setNeedAuth: function (_: boolean): void {
      throw new Error('Function not implemented.');
    }
  }
};

const AppContext = createContext(initialValues);

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('GatewayContext not available');
  }
  return context;
};

const AppProvider = ({ children }: BaseProps) => {
  const [protectedSite, setProtected] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [serviceWorkerReady, setServiceWorkerReady] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({});
  const [needAuth, setNeedAuth] = useState<boolean>(false);
  const [searchBox, setSearchBox] = useState<string | undefined>();

  useSWComm({
    service: Service.SERVICEWORKER,
    method: Method.STATUS,
    onResponse: (data: any) => {
      setStatus(data);
      setLoggedIn(data.graphqlAuthorized);
      setTimeout(() => {
        setServiceWorkerReady(true);
      }, 1000);
      setInterval(() => {
        App.sendMessage({ service: 'keepalive' });
      }, 10_000);
    }
  });

  const values: AppContextProps = {
    values: {
      protectedSite,
      loggedIn,
      serviceWorkerReady,
      status,
      needAuth,
      searchBox
    },
    setters: {
      setProtected,
      setLoggedIn,
      setNeedAuth,
      setSearchBox
    }
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppProvider;
