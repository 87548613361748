import { createBrowserRouter } from 'react-router-dom';
import React from 'react';

import TrySettle from '../pages/trysettle';
import PaymentInstructions from '../pages/PaymentInstructions';
import Register from '../pages/Register';
import PageLayout from '../shared-components/components/PageLayout';
import SuccessPayment from 'pages/success';
import FailurePayment from 'pages/failure';

const router = createBrowserRouter([
  {
    path: '/',
    Component: PageLayout,
    children: [
      {
        path: '',
        element: <TrySettle />
      },
      {
        path: 'success',
        element: <SuccessPayment />
      },
      {
        path: 'failure',
        element: <FailurePayment />
      },
      {
        path: 'payment_instructions/:merchantKey/:depositAmount',
        element: <PaymentInstructions />
      },
      {
        path: 'register',
        element: <Register />
      }
    ]
  }
]);

export default router;
