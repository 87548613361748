import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import React from 'react';

import { BaseProps } from '../../utils/base_interface';
import Login from '../Modals/Login';
import { useAuthEID } from 'shared-components/providers/AuthEIDProvider';
import { AdminPrefix, AdminUrl, MerchantPrefix } from 'shared-components/configuration';

import Footer from './Footer';
import Chessboard1 from './Chessboard1';
import Chessboard2 from './Chessboard2';
import Header from './Header';

interface PageProps extends BaseProps {
  header?: string;
  chess1Disabled?: boolean;
  chess2Disabled?: boolean;
  fluid?: string | boolean;
}

const PageLayout = ({ header, chess1Disabled, chess2Disabled, fluid }: PageProps) => {
  const { isAdmin } = useAuthEID();

  return (
    <>
      <Login redirectUrl={`${isAdmin ? AdminPrefix : MerchantPrefix}/${AdminUrl.MAIN}`} />
      <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
        <Header type={header} />
        {!chess1Disabled && <Chessboard1 />}
        {!chess2Disabled && <Chessboard2 />}
        <Container fluid={fluid} className="flex-grow-1 z-3 mb-4">
          <Outlet />
        </Container>
        <Footer></Footer>
      </div>
    </>
  );
};

export default PageLayout;
